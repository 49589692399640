<template>
  <main class="page page--dashboard">
    <loader
      :loading="!$store.getters['user/exist']"
      :error="$store.getters['user/error']"
    />
    <section class="section section--dashboard dashboard">
      <div class="dashboard__wrapper">
        <h1 class="dashboard__title title title-lg">
          {{ $t('web.my_services') }}
        </h1>
        <div
          class="dashboard__content dashboard-content"
          :class="{
            'dashboard-content__active': $store.state.user.isPremium,
            'dashboard-content__inactive': !$store.state.user.isPremium,
          }"
        >
          <div class="dashboard-content__item content-item">
            <div class="content-item__title text text-lg text-bold">
              {{ $t('web.private_and_secure') }}
            </div>
            <div v-if="!onHoldOrder" class="content-item__description">
              <span
                class="text text-md"
                v-if="
                  $store.state.user.isPremium &&
                  $store.getters['user/payedUntil']
                "
              >
                {{ $t('web.active_until') }}
                <span class="text text-md">{{
                  $moment($store.getters['user/payedUntil']).format('LL')
                }}</span>
              </span>
              <span
                class="text text-md"
                v-else-if="$store.state.user.isPremium"
              >
                {{ $t('web.active') }}
              </span>
              <span v-else>
                {{ $t('web.inactive') }}
              </span>
            </div>
            <div v-else class="content-item__description">
              <span class="text text-md">
                {{
                  $t('web.onHoldMessage', {
                    platform: onHoldOrder?.platform,
                    productName: PRODUCT_NAME,
                  })
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Loader from '../components/UserLoader'
export default {
  name: 'Dashboard',
  components: {
    Loader,
  },
  computed: {
    onHoldOrder: {
      get() {
        return this.$store.getters['user/onHoldOrder']
      },
    },
    PRODUCT_NAME: {
      get() {
        return process.env.VUE_APP__PRODUCT_NAME
      },
    },
  },
}
</script>

<style scoped lang="scss">
@import 'index';
</style>
